import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FcComboChart } from "react-icons/fc";



const ChartModal = ({ onClose, idForChart }) => {

  // console.log("idForChartidForChart",idForChart);
  const history = useHistory();

  return (
    <div>
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
        {/* Modal Content */}
        <div className="bg-white rounded-lg p-4 w-80 shadow-lg relative">
          {/* Modal Header */}
          <div className="flex justify-end  mb-2">
            <button
              onClick={() => onClose()}
              className="text-gray-400 hover:text-gray-600 focus:outline-none"
            >
              &#10005;
            </button>
          </div>
          <div>
            <h2 className="font-bold text-lg  text-center pb-4 capitalize text-pink-500">{idForChart?.name}</h2>

          </div>


          <div className="flex justify-between place-items-center space-x-2">
            <button onClick={() => history.push(`/app/chartjodi/${idForChart?._id}/${idForChart?.name}`)} className=" flex justify-start place-items-center gap-2 bg-gray-200 text-black font-semibold text-sm px-2 shadow py-1 rounded hover:bg-brown-700 transition" >

              Jodi Chart <FcComboChart className='text-white'/>
            </button>
            <button onClick={() => history.push(`/app/charts/${idForChart?._id}/${idForChart?.name}`)} className=" flex justify-start place-items-center gap-2 bg-gray-200 text-black font-semibold px-2 shadow text-sm py-1 rounded hover:bg-brown-700 transition">
              Panel Chart <FcComboChart className='text-white'/>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChartModal