import React from 'react'
import { FaRegCalendar } from 'react-icons/fa'
import { IoArrowBackOutline } from 'react-icons/io5';
import { HiOutlineChevronRight } from 'react-icons/hi2';
import { BiCreditCard } from "react-icons/bi";
import { BsBank } from "react-icons/bs";
import { RiRefund2Fill } from "react-icons/ri";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { FaRupeeSign } from "react-icons/fa";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


function Funds() {
  const history = useHistory();
  const handleGoBack = () => {
    window.history.back()
  }
  const handleAddFund = () => {
    history.push(`/app/add-fund`)
  }
  const handleWithdraw = () => {
    history.push(`/app/withdraw-fund`)

  }
  const handleBankDetails = () => {
    history.push(`/app/bank-details`)

  }
  const handleAddFundHistory = () => {
    history.push(`/app/funds-history`)

  }

  const handleWithdrawFundHistory = () => {
    history.push(`/app/withdraw-history`)
  }
  return (
    <div className='bg-white'>
      <div className='bg-gray-200 w-full min-h-screen '>
        <div className='h-16 w-full border bgcolor rounded-b-3xl flex justify-start items-center place-items-end px-4 gap-3'>
          <IoArrowBackOutline className='text-white mb-1 text-lg' onClick={handleGoBack} />
          <div className='  font-semibold text-white cursor-pointer'>Funds</div>
        </div>

        <div className='lg:w-[90%] w-[95%]  space-y-4 mx-auto '>
          <div className=" mt-10">
            <div className="max-w-full overflow-hidden overflow-y-auto">
              <div className='Bid_History  shadow-md bg-gray-100 rounded-md cursor-pointer' >
                <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  '>
                  <div className='flex justify-start place-items-center gap-4 px-4'>
                    <div className=''>
                      <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-2 py-2"><FaRupeeSign className="text-xl  text-white" /></span>
                    </div>
                    <div className=''>
                      <p className='font-semibold'>Add Fund</p>
                      <p className='text-gray-400 text-[12px]'>You can add fund to your wallet</p>
                    </div>

                  </div>
                  <div className='px-2' onClick={handleAddFund}>
                    <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                  </div>
                </div>
              </div>
              <div className='Bid_History  shadow-md bg-gray-100 rounded-md my-2'>
                <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  '>
                  <div className='flex justify-start place-items-center gap-4 px-4'>
                    <div className=''>
                      <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-2 py-2"><BiCreditCard className="text-xl  text-white" /></span>
                    </div>
                    <div className=''>
                      <p className='font-semibold'>Withdraw Fund</p>
                      <p className='text-gray-400 text-[12px] '>You can add withdraw winnings </p>
                    </div>

                  </div>
                  <div className='px-2 cursor-pointer' onClick={handleWithdraw}>
                    <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                  </div>
                </div>
              </div> <div className='Bid_History  shadow-md bg-gray-100 rounded-md my-2'>
                <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  '>
                  <div className='flex justify-start place-items-center gap-4 px-4'>
                    <div className=''>
                      <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-2 py-2"><BsBank className="text-xl  text-white" /></span>
                    </div>
                    <div className=''>
                      <p className='font-semibold'>Bank Details</p>
                      <p className='text-gray-400 text-[12px]'>Add your bank detail for withdraws</p>
                    </div>

                  </div>
                  <div className='px-2 cursor-pointer' onClick={handleBankDetails}>
                    <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                  </div>
                </div>
              </div> <div className='Bid_History  shadow-md bg-gray-100 rounded-md '>

              </div> <div className='Bid_History  shadow-md bg-gray-100 rounded-md my-2 '>
                <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  '>
                  <div className='flex justify-start place-items-center gap-4 px-4'>
                    <div className=''>
                      <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-2 py-2"><RiRefund2Fill className="text-xl  text-white" /></span>
                    </div>
                    <div className=''>
                      <p className='font-semibold'>Add Fund History</p>
                      <p className='text-gray-400 text-[12px]'>You can check your add point history</p>
                    </div>

                  </div>
                  <div className='px-2 cursor-pointer' onClick={handleAddFundHistory}>
                    <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                  </div>
                </div>
              </div> <div className='Bid_History  shadow-md bg-gray-100 rounded-md my-2'>
                <div className='flex justify-between place-items-center border-l-2 border-[#C11C84] py-3  ' onClick={handleWithdrawFundHistory}>
                  <div className='flex justify-start place-items-center gap-4 px-4'>
                    <div className=''>
                      <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-2 py-2"><RxCounterClockwiseClock className="text-xl  text-white" /></span>
                    </div>
                    <div className=''>
                      <p className='font-semibold'>Withdraw Fund History</p>
                      <p className='text-gray-400 text-[12px]'>You can check your withdraw point history</p>
                    </div>

                  </div>
                  <div className='px-2 cursor-pointer'>
                    <span className="group-hover:text-[#f97941] bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1"><HiOutlineChevronRight className="text-lg  text-black" /></span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Funds
