import React from 'react';
import { connect } from 'react-redux';
import { betActions, userActions } from '../../_actions';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Sidebar from "../../components/Sidebar/Sidebar";
import { IoArrowBackOutline, IoCloseOutline } from 'react-icons/io5';
import { RiFilterFill } from 'react-icons/ri';


class TransictionHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      isOpen: false,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
      size: 10,
    }
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    this.props.dispatch(betActions.transactionHistoryForWeb(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData =
    {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": this.state.size
    }

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }


  getBatHistory = () => {
    this.setState({ pageNo: 1 }, () => {

      let data = {
        "fromDate": this.state.startDate,
        "toDate": this.state.endDate,
        "keyWord": this.state.sportsId,
        "sortBy": "",
        "sortOrder": "",
        "pageNo": this.state.pageNo,
        "size": this.state.size,

      }

      this.props.dispatch(betActions.transactionHistoryForWeb(data));
    })
  }

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };


  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        "fromDate": this.state.startDate,
        "toDate": this.state.endDate,
        "keyWord": "",
        "sortBy": "",
        "sortOrder": "",
        "pageNo": this.state.pageNo,
        "size": this.state.size,
      }
      this.props.dispatch(betActions.transactionHistoryForWeb(pagination));
    })

  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {

    let { bet, users } = this.props;
    const { isOpen } = this.state;

    let { loading, betLoading, totalBet, tnxTotal, tnxList } = bet;
    let { totalGame, gameList } = users;
    const today = new Date().toISOString().split('T')[0];

    return (

      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className='bg-gray-200 w-full min-h-screen '>
          <div className='flex justify-between place-items-center h-16 items-center w-full border bgcolor rounded-b-3xl'>
            <div className=" flex justify-start place-items-end px-4 gap-3">
              <IoArrowBackOutline
                className="text-white mb-1 text-lg cursor-pointer"
                onClick={() => window.history.back()}
              />
              <div className="  font-semibold text-white cursor-pointer">
                {" "}
                Transaction History
              </div>
            </div>
            <div className="flex justify-start place-items-center text-white px-2 gap-2">
              <span className="font-semibold cursor-pointer ">Filter By</span>
              <span className="relative inline-block">
                <RiFilterFill
                  className="cursor-pointer"
                  onClick={this.toggleDropdown}
                />
                {isOpen && (
                  <div className="absolute right-0 mt-2 w-80 bg-white border text-black border-gray-300 rounded-lg shadow-lg z-[100] p-2">
                    <div className="flex justify-end">
                      <IoCloseOutline
                        className="cursor-pointer"
                        onClick={this.toggleDropdown}
                      />
                    </div>
                    <div className=' '>
                      <div className=''>
                        <label className=''>From Date</label>
                        <input type="date" max={today} className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                          name="Date"
                          dateFormat="yyyy-mm-dd"
                          id="datepicker" required
                          value={this.state.startDate} onChange={this.inputChangeStartDate} />
                      </div>
                      <div className=''>
                        <label className=''>To Date</label>
                        <input type="date" max={today} className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                          name="Date"
                          dateFormat="yyyy-mm-dd"
                          id="datepicker" required
                          value={this.state.endDate} onChange={this.inputChangeEndDate}
                        />
                      </div>

                      <div className='col-span-1 flex flex-col justify-end items-end p-4'>
                        <label className=''> </label>
                        <button onClick={() => this.getBatHistory()} className='py-1.5 px-4 bgblue text-white text-[0.8125rem] rounded'>Submit</button>
                      </div>
                    </div>

                  </div>
                )}
              </span>
            </div>
          </div>
          {/* <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
          <div className=' w-full  overflow-y-auto h-[98vh]'>
            <div className='flex-col  p-3 w-full  flex gap-5 '>
              {tnxList && tnxList.length > 0 && tnxList.map((element, index) => {
                return (
                  <div className=''>
                    <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-sm mx-auto hover:shadow-lg transition-shadow duration-300 ease-in-out">
                      <div className="flex justify-between items-center mb-3">
                        <div className="flex items-center gap-2">
                          <span className="text-lg font-semibold text-gray-700">S.No. 🚀</span>
                          <p className="text-lg font-bold text-indigo-600">{this.state.offset + index + 1}</p>
                        </div>
                        <div className="text-sm text-gray-500">
                          <p>Date 📅</p>
                          <p className="font-medium">
                            {moment(new Date(parseInt(element.createdAt)))
                              .utcOffset("+05:30")
                              .format("DD-MM-YYYY hh:mm A")}
                          </p>
                        </div>
                      </div>

                      <div className="flex justify-between items-center py-2 px-4 bg-gray-100 rounded-md">
                        {element && Number(element?.amount) >= 0 ? (
                          <div className="flex items-center gap-2 text-green-600">
                            <span className="text-xl">💰</span>
                            <p className="font-semibold">Credit</p>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2 text-red-600">
                            <span className="text-xl">💸</span>
                            <p className="font-semibold">Debit</p>
                          </div>
                        )}
                        <span
                          className={`font-bold text-lg ${Number(element?.amount) >= 0 ? "text-green-700" : "text-red-700"
                            }`}
                        >
                          {element?.amount}
                        </span>
                      </div>
                    </div>

                    {/* <div className=' flex w-full justify-between whitespace-nowrap 1 mb-1  '><div>S.No.</div>  {this.state.offset + index + 1}</div> */}
                    {/* <div className=' flex w-full justify-between whitespace-nowrap 1 mb-1  uppercase'><div>Type</div>    {element && element.type && element.type ? element.type : "-"}</div> */}
                    {/* <div className=' flex w-full justify-between whitespace-nowrap 1 mb-1  uppercase'><div>Game</div>{element && element.gameId && element.gameId.name ? element.gameId.name : "-"}</div> */}
                    {/* <div className=' flex w-full justify-between whitespace-nowrap 1 mb-1  uppercase'><div>NUMBER</div>{element && element.betNumber ? element.betNumber : "-"}</div> */}
                    {/* <div className=' flex w-full justify-between whitespace-nowrap 1 mb-1  capitalize'>
                          {element && Number(element?.amount) >= 0 && <div>Credit</div>}
                          {element && Number(element?.amount) < 0 && <div>Debit</div>}
                          {element && Number(element?.amount) >= 0 && <span className='text-green-700'> {element?.amount}</span>}
                          {element && Number(element?.amount) < 0 && <span className='text-red-700'> {element?.amount}</span>}
                        </div> */}
                    {/* <div className=' flex w-full justify-between whitespace-nowrap 1 mb-1  uppercase'><div>BID TYPE</div>{element && element.isOpen ? "Open" : "Close"}</div> */}
                    {/* <div className=' flex w-full justify-between whitespace-nowrap 1 mb-1  '> */}

                    {/* <div>Date</div>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm A")}</div> */}
                    {/* <div className=' flex w-full justify-between whitespace-nowrap 1 mb-1  uppercase'><div>Status</div>  {element && element.betStatus == 0 && <div className=' text-yellow-500'>Pending</div>}
                          {element && element.status == 0 && <div className=' text-yellow-500'>Pending</div>}
                          {element && element.status == 1 && <div className=' text-green-800'>Success</div>}
                          {element && element.status == 2 && <div className=' text-red-700'>Rejected</div>}
                        </div> */}
                  </div>
                )
              })}
            </div>
            {

              <nav className="relative z-0 flex mb-10 justify-end  w-76">
                {
                  tnxTotal && tnxTotal > 10 ?
                    <ReactPaginate
                      previousLabel={'Prev'}
                      nextLabel={'Next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={tnxTotal / 10}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      pageClassName={'page-cls'}
                      activeClassName={'active'}
                    />
                    : null}
              </nav>
            }




          </div>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet
  };
}

export default connect(mapStateToProps)(TransictionHistory);

