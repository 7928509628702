import React from "react";
import { connect } from "react-redux";
import { betActions, userActions } from "../../_actions";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FaRegCalendar } from "react-icons/fa";
import { HiOutlineChevronRight } from "react-icons/hi2";
import { RxCountdownTimer } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";

class satteledBet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
      size: 10,
    };
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),
      // "keyWord": "",
      sortBy: "",
      sortOrder: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
    };

    this.props.dispatch(betActions.betHistory(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData = {
      // "keyWord": "",
      pageNo: this.state.pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 200,
    };

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }

  getBatHistory = () => {
    let data = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      gameId: this.state.sportsId,
      sortBy: "",
      sortOrder: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
    };

    this.props.dispatch(betActions.betHistory(data));
  };

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  };

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };

  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  };

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        // "keyWord": "",
        gameId: this.state.sportsId,
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
      };
      this.props.dispatch(betActions.betHistory(pagination));
    });
  };

  goBack = () => {
    window.history.back();
  };

  render() {
    let { bet, users } = this.props;
    let { betList, loading, betLoading, totalBet } = bet;
    let { totalGame, gameList } = users;
    const today = new Date().toISOString().split("T")[0];
    // const navigate = useNavigate();
    const handleNavigate = () => {
      this.props.history.push("/app/bidhistory");
    };

    const handleGameResultNavigate = () => {
      this.props.history.push(`/app/game-result`);
    };
    const handleStarlineResultHistory = () => {
      this.props.history.push(`/app/indian-starline-result-history`);

    }
    const handleStarlineResult = () => {
      this.props.history.push(`/app/indian-starline-history`);
    }
    const handleIndianJackpot = () => {
      this.props.history.push(`/app/indian-jackpot-result`);
    }
    const handleIndianJackpotResult = () => {
      this.props.history.push(`/app/indian-jackpot-bid-result`);

    }
    return (
      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className="bg-gray-200 w-full min-h-screen  ">
          <div className="h-16 w-full border bgcolor rounded-b-3xl flex justify-start items-center px-4 text-white space-x-3">
            <IoArrowBackOutline className="cursor-pointer h-6 w-6" onClick={this.goBack} />
            <div className="font-semibold cursor-pointer">My Bids</div>
          </div>
          {/* <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
          <div className=" px-1 space-y-4 mx-auto ">
            {/* <div className='  border grid grid-cols-1 lg:grid-cols-7 divide-x  lg:divide-y-0 '>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>From Date</label>
                <input type="date" max={today} className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.startDate} onChange={this.inputChangeStartDate} />
              </div>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>To Date</label>
                <input type="date" max={today} className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.endDate} onChange={this.inputChangeEndDate}
                />
              </div>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>Game Name</label>
                <select onChange={this.handleChangeSelect} name="sports_id" className=' w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none block'>
                  <option value="">All</option>
                  {gameList && gameList.length > 0 ? gameList.map((element, index) => (
                    <option value={element && element._id} className='capitalize '>{element && element.name}</option>
                  )) : null}
                </select>
              </div>
              <div className='col-span-1 flex flex-col justify-end items-end p-4'>
                <label className=''> </label>
                <button onClick={() => this.getBatHistory()} className='py-1.5 px-4 bgblue text-white text-[0.8125rem] rounded'>Submit</button>
              </div>
            </div> */}
            <div className="mt-5">
              <div className="max-w-full overflow-hidden overflow-y-auto">
                <div className="Bid_History shadow-md bg-gray-100 rounded-md cursor-pointer" onClick={handleNavigate}>
                  <div className="flex justify-between place-items-center border-l-2 border-[#EA7A04] py-3  ">
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="bgcolor rounded-full flex justify-center place-items-center px-2 py-2">
                          <FaRegCalendar className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">Bid History</p>
                        <p className="text-gray-600 text-[12px]">
                          You can add fund to your wallet
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span className="bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1">
                        <HiOutlineChevronRight className="text-lg text-black" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Bid_History shadow-md bg-gray-100 rounded-md my-2">
                  <div className="flex justify-between place-items-center border-l-2 border-[#EA7A04] py-3 cursor-pointer" onClick={handleGameResultNavigate}>
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="bgcolor rounded-full flex justify-center place-items-center px-2 py-2">
                          <RxCountdownTimer className="text-xl text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">Game Result</p>
                        <p className="text-gray-600 text-[12px] ">
                          You can View Your market result history{" "}
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span
                        className="bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1 cursor-pointer"
                        onClick={handleGameResultNavigate}
                      >
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div>{" "}
                <div className="Bid_History  shadow-md bg-gray-100 rounded-md my-2">
                  <div className="flex justify-between place-items-center border-l-2 border-[#EA7A04] py-3 cursor-pointer" onClick={handleStarlineResult}>
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="bgcolor rounded-full flex justify-center place-items-center px-2 py-2">
                          <FaRegCalendar className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">
                          Starline Bid History
                        </p>
                        <p className="text-gray-600 text-[12px]">
                          You can view starline history
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span className="bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1 cursor-pointer" onClick={handleStarlineResult}>
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div>{" "}
                <div className="Bid_History  shadow-md bg-gray-100 rounded-md ">
                  <div className="flex justify-between place-items-center border-l-2 border-[#EA7A04] py-3 cursor-pointer" onClick={handleStarlineResultHistory}>
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="bgcolor rounded-full flex justify-center place-items-center px-2 py-2">
                          <FaRegCalendar className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">
                          Starline Result History
                        </p>
                        <p className="text-gray-600 text-[12px]">
                          You can view starline result
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span className="bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1 cursor-pointer" onClick={handleStarlineResultHistory}>
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div>{" "}
                <div className="Bid_History  shadow-md bg-gray-100 rounded-md my-2 ">
                  <div className="flex justify-between place-items-center border-l-2 border-[#EA7A04] py-3 cursor-pointer" onClick={handleIndianJackpotResult}>
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="bgcolor rounded-full flex justify-center place-items-center px-2 py-2">
                          <FaRegCalendar className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">
                          Jackpot Bid History
                        </p>
                        <p className="text-gray-600 text-[12px]">
                          You can view Jackpot history
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span className="bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1 cursor-pointer" onClick={handleIndianJackpotResult}>
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div>{" "}
                <div className="Bid_History  shadow-md bg-gray-100 rounded-md my-2">
                  <div className="flex justify-between place-items-center border-l-2 border-[#EA7A04] py-3 cursor-pointer" onClick={handleIndianJackpot}>
                    <div className="flex justify-start place-items-center gap-4 px-4">
                      <div className="">
                        <span className="bgcolor rounded-full flex justify-center place-items-center px-2 py-2">
                          <FaRegCalendar className="text-xl  text-white" />
                        </span>
                      </div>
                      <div className="">
                        <p className="font-semibold">
                          Jackpot Result History
                        </p>
                        <p className="text-gray-600 text-[12px]">
                          You can view Jackpot result
                        </p>
                      </div>
                    </div>
                    <div className="px-2">
                      <span className="bg-gray-400 rounded-full flex justify-center place-items-center px-1 py-1  cursor-pointer" onClick={handleIndianJackpot}>
                        <HiOutlineChevronRight className="text-lg  text-black" />
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet,
  };
}

export default connect(mapStateToProps)(satteledBet);
