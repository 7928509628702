
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { TiInfoLarge } from "react-icons/ti";
import { FaStar } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { GiPodiumWinner } from "react-icons/gi";
import { userActions } from "../../_actions";
import { IoChevronBack } from "react-icons/io5";
import { IoMdHome } from "react-icons/io";
import { RiArrowLeftRightFill } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import { IoMdKey } from "react-icons/io";
import { TfiWallet } from "react-icons/tfi";
import { GoCreditCard } from "react-icons/go";
import { RxCountdownTimer } from "react-icons/rx";
import { BsBank } from "react-icons/bs";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { MdBarChart } from "react-icons/md";
import { FaStopwatch } from "react-icons/fa";
import { MdPermDeviceInformation } from "react-icons/md";
import { FaShareAlt } from "react-icons/fa";
import user from "./user.png"
import moment from "moment";
function Sidebar(props) {
  const { opensizebar, setopensizebar } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { getProfile, loading } = users ? users : {}
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [sport_id, setSport_id] = useState(props.sportId || null);


  const onClickMenu = (url) => {
    history.push(url);
    setNavbarOpen(!navbarOpen);
    setopensizebar(false)
  };

  useEffect(() => {

    dispatch(userActions.getProfile())
  }, [opensizebar]);

  const showList = (ele, url) => {
    setSport_id(ele.sport_id);
    let data1 = {
      limit: 1,
      pageno: 1,
      sport_id: ele.sport_id,
    };
    // dispatch(userActions.event_game_list(data1));

    history.push(url + ele.sport_id);
  };

  const handleClickInside = () => {
    // Handle click inside
  };

  const handleLogout = () => {
    confirmAlert({
      title: '',
      message: ` Are you sure you want to Logout?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/login');
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }
  const handleLogout2 = () => {
    confirmAlert({
      title: '',
      message: ` Are you sure you want to Delete Account?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(userActions.deleteUser())
            localStorage.clear();
            sessionStorage.clear();
            history.push('/login');
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }



  // console.log("----<",getProfile)
  const createdAt = getProfile?.createdAt ? moment(getProfile.createdAt) : "";
  const formattedDate = createdAt ? moment(createdAt).format("DD/MM/YYYY") : "";

  return (
    <div className={`shadow-lg relative transition-all flex duration-100 ease-in-out w-${opensizebar ? '72' : '0'} h-screen border-t-8 border-black rounded-t-xl`}>
      <div className={`flex flex-col  rounded-lg transition-all duration-100 ease-in-out    ${opensizebar ? ' w-64' : 'w-0'}  bg-[white] overflow-hidden relative h-[99%]`}>
        {/* <div className=" bg-[#f8713e] h-40  w-48 rounded-full absolute -top-28 right-10 "></div> */}
        <div className="flex flex-col flex-1  ">
          <div className="text-[#3B5160]  md:relative">

            <div className="flex justify-between border-b border-[#C11C84] mb-2 p-4 items-center">
              <div className="flex justify-start place-items-center gap-4">
                <div className="bgcolor p-1 mb-2 rounded-full flex justify-center items-center h-12 w-12">
                  <img src={user} className="h-8" />
                </div>
                <div className="flex flex-col">
                  <div className="gap-2 font-semibold capitalize">{getProfile && getProfile["userName"] ? getProfile["userName"] : ""}</div>
                  <div className="gap-2 font-semibold text-sm">{getProfile && getProfile["mobNo"] ? getProfile["mobNo"] : ""}</div>
                  <small>Since {formattedDate}</small>
                </div>
              </div>
              <div className="">
                <IoChevronBack className=" font-semibold cursor-pointer" onClick={onClickMenu} />
              </div>
            </div>


            <div className=" px-4 flex flex-col gap-2  overflow-y-auto h-[95vh]  ">
              <span onClick={() => onClickMenu('/app/dashboard')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/dashboard" ? "textcolor bg-[#e4ddda]  rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#d6b0a0] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><IoMdHome className="text-xl text-white" /></span>
                <span className="text-sm ">Home</span>
              </span>


              {/* <span onClick={() => onClickMenu('/app/my_profile')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/my_profile" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941]"><FaRegUserCircle className="text-xl" /></span>
                <span className="text-sm     ">MyProfile</span>
              </span> */}

              <span onClick={() => onClickMenu('/app/add-fund')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/my_profile" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><TfiWallet className="text-lg  text-white" /></span>
                <span className="text-sm ">Add Money</span>
              </span>



              <span onClick={() => onClickMenu('/app/withdraw-fund')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/my_profile" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><GoCreditCard className="text-lg  text-white" /></span>
                <span className="text-sm">Withdraw Money</span>
              </span>

              <span onClick={() => onClickMenu('/app/my-bets')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/my-bets" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><RxCountdownTimer className="text-lg text-white" /></span>
                <span className="text-sm     ">My Bid </span>
              </span>



              <span onClick={() => onClickMenu('/app/win_history')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><GiPodiumWinner className="text-xl text-white" /></span>
                <span className="text-sm     ">Win History</span>
              </span>

              <span onClick={() => onClickMenu('/app/funds')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><BsBank className="text-lg text-white" /></span>
                <span className="text-sm    ">Funds</span>
              </span>
              <span onClick={() => onClickMenu('/app/notification')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><MdOutlineNotificationsActive className="text-lg text-white" /></span>
                <span className="text-sm     ">Notification</span>
              </span>

              <span onClick={() => onClickMenu('/app/chart')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><MdBarChart className="text-lg text-white" /></span>
                <span className="text-sm     ">Game Chart</span>
              </span>

              <span onClick={() => onClickMenu('/app/starline-chart')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><MdBarChart className="text-lg text-white" /></span>
                <span className="text-sm">Starline Chart</span>
              </span>

              <span onClick={() => onClickMenu('/app/jackpot-chart')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><MdBarChart className="text-lg text-white" /></span>
                <span className="text-sm     ">Jackpot Chart</span>
              </span>

              <span onClick={() => onClickMenu('/app/time-table')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><FaStopwatch className="text-lg text-white" /></span>
                <span className="text-sm ">Time Table</span>
              </span>

              {/* 
              <span onClick={() => onClickMenu('/app/deposite_amount')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/deposite_amount" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941]"><FaRegMoneyBillAlt  className="text-xl" /> <i class="fa-solid fa-money-bill-transfer"></i></span>
                
                <span className="text-sm     ">Deposit Amount</span>
              </span> */}


              {/* <span onClick={() => onClickMenu('/app/withdraw')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/withdraw" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941]"><GiTakeMyMoney  className="text-xl" /> <i class="fa-solid fa-money-bill-transfer"></i></span>
                
                <span className="text-sm     ">Withdraw Amount</span>
              </span> */}


              <span onClick={() => onClickMenu('/app/wallet_history')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/wallet_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><RiArrowLeftRightFill className="text-md text-white" /></span>
                <span className="text-sm     ">Transaction History</span>
              </span>

              <span onClick={() => onClickMenu('/app/notice-board')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><MdPermDeviceInformation className="text-lg text-white" /></span>
                <span className="text-sm   ">Notice Board / Rules</span>
              </span>

              {/* <span onClick={() => onClickMenu('/app/settings')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><IoMdSettings className="text-lg text-white" /></span>
                <span className="text-sm     ">Settings</span>
              </span> */}

              {/* <span  className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><IoIosPlay className="text-lg text-white" /></span>
                <span className="text-sm     ">Video</span>
              </span> */}

              <span className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><FaShareAlt className="text-lg text-white" /></span>
                <span className="text-sm     ">Share</span>
              </span>


              {/* <span onClick={() => onClickMenu('/app/transfer_points')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/transfer_points" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941]"><BiTransfer className="text-xl" /></span>
                <span className="text-sm     ">Transfer Points</span>
              </span> */}
              <span onClick={() => onClickMenu('/app/ChangePassword')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/ChangePassword" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><IoMdKey className="text-lg text-white" /></span>
                <span className="text-sm     ">Change Password</span>
              </span>
              {/* <span onClick={() => onClickMenu('/app/chart')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/chart" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941]"><FaChartLine className="text-lg" /></span>
                <span className="text-sm     ">Chart</span>
              </span> */}

              <span onClick={() => onClickMenu('/app/gameRate')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/gameRate" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><FaStar className="text-lg text-white" /></span>
                <span className="text-sm     ">Game Rate</span>
              </span>
              {/* <span onClick={() => onClickMenu('/app/example')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><FaStopwatch className="text-lg text-white" /></span>
                <span className="text-sm ">Number</span>
              </span> */}



              {/* <span onClick={() => onClickMenu('/app/adminBank')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/adminBank" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941]"><BsBank2 className="text-lg" /></span>
                <span className="text-sm     ">Bank Deposit Details</span>
              </span> */}
              <span onClick={() => onClickMenu('/app/ledger')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/ledger" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><TbReportAnalytics className="text-lg text-white" /></span>
                <span className="text-sm     ">My Statement</span>
              </span>

              <span onClick={() => onClickMenu('/app/howtoPlay')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/howtoPlay" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><TiInfoLarge className="text-lg text-white" /></span>
                <span className="text-sm     ">How To Play</span>
              </span>

              <span onClick={() => handleLogout()} className={`cursor-pointer h-9 pb-32 pt-3  items-center flex space-x-4 px-1  text-base transition ease-in-out duration-150 ${location.pathname === "/app/Logout" ? "textcolor bgcolor rounded-md" : "textcolor "}`}>
                <span className="group-hover:text-[#f97941] bgcolor rounded-full flex justify-center place-items-center px-1 py-1"><IoLogOutOutline className="text-lg text-white" /></span>
                <span className="text-sm     ">Logout</span>
              </span>




            </div>

          </div>
        </div>
      </div>
      <div onClick={() => setopensizebar(prev => !prev)} className="  flex-1"></div>
    </div>
  );
}

function mapStateToProps(state) {
  const { users } = state;
  const { user } = state.authentication;
  return {
    users,
    user,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
